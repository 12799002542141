import './App.css';
import { useState } from 'react'
import TopNav from './navTools/TopNav'
import Home from './pages/Home/Home'
import { BrowserRouter as Router, Routes, Route, useSearchParams, useParams } from 'react-router-dom';
import Footer from './navTools/Footer.js';
import MusicPage from './pages/MusicPage';
import AppsPage from './pages/AppsPage';
import SpinDrawPage from './pages/Apps/SpinDraw3D/SpinDrawPage';
import * as Colors from './components/Colors'
import PrivacyPolicy from './pages/Apps/SpinDraw3D/PrivacyPolicy';

function App() {
  //const backgroundColor = '#121212'
  const [sel, setSel] = useState(0)
  
  const [searchParams] = useSearchParams();
  const { id, en } = useParams(); // Unpacking and retrieve id
  const LinkedInRedirect = ({}) => {
    return <> 
        <div style={{
          fontSize: '10vw',
          color: '#00ff00',
          textAlign: 'center',
          margin: 'auto',
          position: 'relative',
          padding: '20vw',
        }} >
          <i>redirecting to Linkedin...</i>
          <div>
            <i style={{fontSize: '4vw'}}>see you soon</i>
          </div>

        </div>

        <meta http-equiv = 'refresh' content = "0; url = https://www.linkedin.com/in/josh-davis-dev/"/>

    </>
  }
  
  return (
    <div>
      <div className='crt'/>
      {/* <div className='crt'/> */}
      {/* <Router classname="App"> */}
      {/* <header style/> */}
      {/* <div style={{color: 'white'}}>params {}</div> */}
      <TopNav setSel={setSel} sel={sel}/>
      {/* <div style={{paddingTop: '100px', backgroundColor: Colors.background}}/> */}
      <Routes>
          <Route path='/' exact element={<Home onSel={setSel}/>} />
          {/* <IndexRoute component={Home} /> */}
          <Route path='/404' exact element={<Home onSel={setSel}/>} />
          <Route path='/music' exact element={<MusicPage setSel={setSel}/>}/>
          <Route path='/apps' exact element={<AppsPage setSel={setSel}/>} />
          <Route path='/Spindraw3D' exact element={<SpinDrawPage/>} />
          <Route path='/Spindraw3D/privacy' exact element={<PrivacyPolicy/>} />
          <Route path='/Sorta-ball' exact element={<SpinDrawPage/>} />
          <Route path='/Sorta-ball/privacy' exact element={<PrivacyPolicy/>} />
          <Route path='/in' exact element={<LinkedInRedirect/>} />
          <Route path='/linkedIn' exact element={<LinkedInRedirect/>} />


        </Routes>
        <Footer/>
      {/* </Router> */}
      {/* <div>{searchParams.userId}</div> */}


    </div>

  );
}

export default App;
