import "./Home.css";
import {Link} from "react-router-dom";
import { Wave } from "react-animated-text";
import Image from "react-bootstrap/Image";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import gfm from "remark-gfm";
// import * as THREE from 'three'
import {useEffect, useRef, useState} from 'react'
import TerminalAboutMe from '../../components/TerminalAboutMe/TerminalAboutMe.js'
// import Typed from 'react-typed';
import TypeAnimation from 'react-type-animation';
import Lottie from 'react-lottie';
import shrugJson from '../../assets/shrug.json'
import tableFlipJson from '../../assets/tableflip.json'
import { BranchPredictorCS152, PintosCS162, CounterstrikeMarketProject, BYOWProject, GaslightProject, GitletProject,  HackedOtomatoneProject,  LavaMarkupGenerationProject,  MinecraftAWSServerProject,  NumCProject, SortaBallProject, SpinDrawProject, TTTProject, WebsiteProject, CS170Project, RiscVCPUProject, CS151Project } from "./Projects.js";

// import InfectionGame from '../components/InfectionGame/InfectionGame.js'
const Box = ({text, color}) => {
  return(
    <div className="bigBox">
      
      <div className="box" style={{outlineColor: color, borderColor: color}}/>
      <div className="textBox">
          <span>{text}</span>
      </div>
</div>
  )
}

function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(value => value + 1);
}
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
const greenColor = '#00ff00'

const Spacer = () => {
  return <div style={{padding: "50px"}}/>
}

function Home({ onSel }) {

  const [isPersonal, setPersonal] = useState(true)
  const filledStyleProj = {float: 'left', color: 'black', padding: '10px', backgroundColor: '#00ff00'}
  const unfilledStyleProj = {float: 'left', color: '#00ff00', border: '1px solid white',padding: '10px'}
  const forceUpdate = useForceUpdate();
  const { height, width } = useWindowDimensions();

  const shrugOptions = {
    loop: true,
    autoplay: true, 
    animationData: shrugJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
    // width: "100px",
    // height: "100px" 
  };

  const tableFlipOptions = {
    loop: true,
    autoplay: true, 
    animationData: tableFlipJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
    width: "100%",
    height: "100%" 
  };

  useEffect(() => {
    onSel(0)
  }, [])

  const bio = `
    bruh this wont work :0
    **hey** whats up.
    # this is a website
  `;

  const Title = styled.div`
    font-size: xx-large;
    font-weight: bold;
    justify-content: center;
    color: #00ff00;

    //padding-left: 50px;
  `;
  
  // const mountRef = useRef(null);


  // useEffect(() => {
  //   if (!mountRef.current) {
  //     return;
  //   }
  //   var boxsize = 500
  //   var scene = new THREE.Scene();
  //   var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
  //   var renderer = new THREE.WebGLRenderer();

  //   renderer.setSize( boxsize, boxsize );
  //   mountRef.current.appendChild( renderer.domElement );

  //   var geometry = new THREE.BoxGeometry( 1, 1, 1 );
  //   var material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
  //   var cube = new THREE.Mesh( geometry, material );

  //   scene.add( cube );
  //   camera.position.z = 5;

  //   var animate = function () {
  //     requestAnimationFrame( animate );
  //     cube.rotation.x += 0.01;
  //     cube.rotation.y += 0.01;
  //     renderer.render( scene, camera );
  //   }

  //   let onWindowResize = function () {
  //     camera.aspect = window.innerWidth / window.innerHeight;
  //     camera.updateProjectionMatrix();
  //     renderer.setSize( window.innerWidth, window.innerHeight );
  //   }

  //   window.addEventListener("resize", onWindowResize, false);

  //   animate();

  //   // return () => mountRef.current.removeChild( renderer.domElement);
  // }, []);

  return (
    <body className="Home">
      <div>

      <div className="topbox" style={{
        // backgroundColor: '#000060',
        width: '300px',
        borderRadius: '25px',
        padding: '10px',
        // textAlign: 'left',
      }}>


        {/* <Title>Joshua Davis</Title> */}
        {/* <Wave text="Explosive Fuzz" /> */}
        {/* <div className="emmabox">NOW IN 3D</div> */}

        <div className="texttop">

        <TypeAnimation
        // speed={500}
        wrapper="h2"
        // style={{fontSize: "5vw", width: "6vw",color: "#00ff00", textAlign: "left"}}
          sequence={['Joshua Davis.', 2000, 'Developer.', 2000, 'Engineer.', 2000, 'Musician.', 2000]}
          // typeSpeed={200}
          // backSpeed={100}
          repeat={Infinity}
        />
        
        {/* <div style={{flexDirection: 'column'}}>
          <span className='subTitle'>Developer.</span>
          <span className='subTitle'>Musician.</span>
          <span className='subTitle'>Engineer.</span>
        </div> */}
        </div>

        <p style={{padding: '20px', 
      textAlign: 'left', 
      fontSize: '12px',
      width: '80vw',
      margin: 'auto',
      color: greenColor
      }}>
      <b>{
       `
      I am Joshua Davis a self-taught programmer and Computer Science student at University of California Berkeley.
      I am always eager to learn new things and find new ways to solve problems. 
      My biggest strengths are my creativity to explore unique ideas and my urge to create exciting new things. 
      In my free time I make mobile applications and compose, produce, and sell instrumental beats online. 
      I am a IOS native application developer with experienced knowledge with SwiftUI, and UIKit. 
      As well as IOS development, I do cross-platform mobile development with React Native.
      As well as mobile apps, I am experienced with React for frontend/desktop development.
      I also am familiar with SQL, Graph (Neo4j), and JSON databases (Firebase).
      `}
      </b>
      </p>

      </div>

      <div style={{
        margin: 'auto', 
        textAlign: 'center',
        // backgroundColor: 'white',
        padding: '10px'
      }}>

        <div style={{color: 'black'}}>
          {/* <div><b>Junior, Bachelors of Computer Science at University of California, Berkeley</b></div> */}
          {/* <div style={{color: '#00ff00', backgroundColor: 'blue'}}><i><b>Josh Porfolio Terminal</b></i></div> */}
        
          <div style={{width: '20px', height: '20px'}}/>
          <TerminalAboutMe/>
          <div style={{width: '20px', height: '20px'}}/>

          <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          >
            {/* <div className="spookyline"/> */}
            <div style={{width: '50px'}}/>

            {/* <img src="https://i.ibb.co/Wz9qXXL/Berkeleybashmap3.png" 
              style={{width: '80vw'}}
            /> */}

            <div style={{width: '25px'}}/>

            {/* <div className="spookyline2"/> */}

          </div>
        </div>

      </div>

      {/* <div> */}
        {/* <div style= {{
          fontSize: '8vw',
          padding: '20px',
          paddingBottom: '50px',
          textAlign: 'center'
        }}><b><u>PROJECTS</u></b></div> */}
      <h2>Work Experience.</h2>
      <div style={{ width: "100%", borderTop: "2px solid #00ff00", paddingBottom: '20px'}}/>
      
      {/* <div style={{padding: '10px'}}/> */}
      <Lottie options={tableFlipOptions}
              height={100}
              width={width > 500 ? 500 : width - 50}
              style= {{ margin: '0px', pointerEvents: 'none'}}
        />
      
      <div style={{paddingLeft: "20px"}}>
        <div><b>Software Engineer Intern (Ad Platforms).</b></div>
          <div>Apple.</div>
          <div>Summer 2023.</div>
          <div>* Designed and Implemented a new prototype advertising format and received feedback from members of the privacy and product teams</div>
          <div>* Built an internal debugging tool with SwiftUI to more easily design advertisements and debug issues</div>
          <div>* Modified existing ad placements to reduce memory usage by over 20%</div>
        <div style={{padding: '20px'}}/>

        <div><b>Software Engineer Intern.</b></div>
        <div>Florence Healthcare.</div>
        <div>Summer 2022.</div>
        <div>* Developed the Landing Page, Task Viewer, and Document List Views for Florence’s IOS application using SwiftUI, Combine, and CombineFeedback</div>
        <div>* Integrated with Florence's backend APIs by creating endpoints with Alamofire to download reports</div>
        <div>* Created code generation scripts with Sourcery to generate boilerplate for screens and auto-initialize dependencies</div>
        <div>* Using Mustache templating language modified existing scripts to generate Swift API from JSON with OpenAPI specification</div>  
      <div style={{padding: '20px'}}/>

      <div>
        <div><b>ASE Course Staff Tutor CS61C.</b></div>
        <div>University of California, Berkeley.</div>
        <div>Spring 2023 - Present.</div>
        <div>* Teach small review sections</div>
        <div>* Create course notes and practice problems</div>
        <div>* Aid students in Office Hours understand content and debug issues</div>

      </div>

      <div style={{padding: '20px'}}/>

      <div>
        <div><b>ASE Course Staff Tutor EECS16A.</b></div>
        <div>University of California, Berkeley.</div>
        <div>Fall 2022.</div>
        <div>* Aided students during lab section</div>
      </div>
      </div>

      <h2>Research.</h2>
      <div style={{ width: "100%", borderTop: "2px solid #00ff00", paddingBottom: '20px'}}/>

      <div style={{paddingLeft: "20px"}}>

      <div>
        <div><b>Undergraduate Researcher at Slice Lab ~ HAMMER</b></div>
        <div>University of California, Berkeley.</div>
        <div>Fall 2023 - Present.</div>
        <div>* Building a more accurate and easy to use warning system when synthasizing Verilog with HAMMER</div>
        <div>* Find needlessly complex synthasized components and warn users</div>
        <div>* Collecting and formatting data to display in a better manner</div>
      </div>
      </div>

      <h2>Projects.</h2>
      <div style={{ width: "100%", borderTop: "2px solid #00ff00", paddingBottom: '20px'}}/>
      
      <div>
      <div style={{alignContent: 'center', justifyContent: 'center', display: 'flex',  border: '2px solid #00ff00', padding: '10px', margin: 'auto', width: '350px', 
                    background: `repeating-linear-gradient(
                                    45deg,
                                    #007700,
                                    black 10px,
                                    #007700 10px,
                                    black 20px
                                  )`}}>
        <div style={(isPersonal ? filledStyleProj : unfilledStyleProj)} onClick={()=>{setPersonal(true)}}>Personal</div>
        <div style={{paddingLeft: '50px'}}/>
        <div style={(isPersonal ? unfilledStyleProj : filledStyleProj)} onClick={()=>{setPersonal(false)}}>Academic</div>
      </div>

      <div style={{textAlign: 'center', padding: '5px', fontSize: '12px'}}>(select to view either my personal or academic projects)</div>

      <div style={{ paddingBottom: '20px'}}/>

      {(() => {
        if (isPersonal) {
          return (
            <>
      <WebsiteProject/>
      <CounterstrikeMarketProject/>
      <LavaMarkupGenerationProject/>
      <GaslightProject/>
      <MinecraftAWSServerProject/>
      <TTTProject/>
      <SortaBallProject/>
      <SpinDrawProject/>

            
            </>

          )
        } else {
          return (
            <>
            <CS151Project/>
            <PintosCS162/>
            <BranchPredictorCS152/>
            <RiscVCPUProject/>
            <CS170Project/>
            <HackedOtomatoneProject/>
            <NumCProject/>
            <BYOWProject/>
            <GitletProject/>

            </>

          )
        }

      })()}
      {/* </div>   */}
      </div>

      {/* <div style={{
        color: 'black', 
        backgroundColor: 'white', 
        padding: '20px'}}> */}

      {/* <h2>Technologies.</h2>
      <div style={{ width: "100%", borderTop: "2px solid #00ff00", paddingBottom: '20px'}}/>
      
      <div>
        <div>Native IOS/MacOS/IPadOS Development</div>
        <div>SwiftUI 🟩🟩🟩🟩🟩</div>
        <div>UIkit 🟩🟩🟩🟩🟩</div>
        <div>React Native 🟩🟩🟩</div>
        <div>Flutter 🟩🟩🟩</div>
        <div>Firebase</div>
      </div> */}

      <h2>Awards.</h2>
      <div style={{ width: "100%", borderTop: "2px solid #00ff00", paddingBottom: '20px'}}/>
      

      <div style={{
        paddingLeft: '20px',
      }}>
          <div style={{paddingBottom: '20px'}}><b>👑 Invited and attended Apple WWDC: 2021</b></div>
        <div style={{paddingBottom: '20px'}}><b>👑 Apple swift student challenge winner: 2020</b></div>
        <div style={{paddingBottom: '20px'}}><b>👑 Cal alumni leadership scholarship: 2020</b></div>
        <div style={{paddingBottom: '20px'}}><b>👑 Fulton county technology competition (MOBILE APPS) 2st place: 2020</b></div>
        <div style={{paddingBottom: '20px'}}><b>👑 State of Georgia Technology Competition (Mobile Apps) 1st place: 2018</b></div>
        <div style={{paddingBottom: '20px'}}><b>👑 Fulton county technology competition (MOBILE APPS) 1st place: 2018, 2019</b></div>

        {/* <div><b>NATIONAL SOCIETY OF LEADERSHIP AND SUCCESS MEMBER: 2021</b></div> */}
      {/* </div> */}
      {/* <div style= {{
          fontSize: '8vw',
          padding: '20px',
          paddingBottom: '50px',
          textAlign: 'center'
        }}><b><u>AWARDS</u></b></div> */}


      {/* <InfectionGame/> */}
      </div>
      {/* <img 
      src="https://i.ibb.co/64nyjZB/joshuadavisresume.jpg"
      width="100%"
      /> */}
      {/* <div onClick={() => {
        fetch('https://media-exp1.licdn.com/dms/document/C4E2DAQHQ6Aef1e8qXw/profile-treasury-document-pdf-analyzed/0/1628142097648?e=1636149600&v=beta&t=KJpx7SI4XHQyx5rOWr2hyXr8ad6BHsIfLiizxhGYnj0', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
          },
        })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `FileName.pdf`,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });

      }}>
        PRESS ME
      </div> */}
      {/* <Link to="https://i.ibb.co/64nyjZB/joshuadavisresume.jpg" target="_blank" download>Download RESUME</Link> */}

      <h2>Extracurricular.</h2>
      <div style={{ width: "100%", borderTop: "2px solid #00ff00", paddingBottom: '20px'}}/>
      {/* <div style={{paddingLeft: "20px"}}> */}
      

      <div style= {{paddingLeft: '20px'}}>
        <div><b>Independent iOS App Developer.</b></div>
        <div>5000+ total downloads with 4 total Apps.</div>
        <div>Fall 2018 - Present.</div>
      </div>
      <div style={{padding: '20px'}}/>

      <div style= {{paddingLeft: '20px'}}>
        <div><b>Composer.</b></div>
        <div>UC Berkeley Undergraduate Composer Club.</div>
        <div>Fall 2021 - Present.</div>

      </div>

      <div style={{padding: '20px'}}/>

      <div style= {{paddingLeft: '20px'}}>
        <div><b>Senior Mentor.</b></div>
        <div>UC Berkeley Computer Science Mentors.</div>
        <div>Spring 2022 - Present.</div>
      </div>

      <div style={{padding: '20px'}}/>

      <div style= {{paddingLeft: '20px'}}>
        <div><b>Junior Mentor.</b></div>
        <div>UC Berkeley Computer Science Mentors.</div>
        <div>Spring 2021.</div>
      </div>
      <div style={{padding: '20px'}}/>

      <h2>Contact Me.</h2>
      <div style={{ width: "100%", borderTop: "2px solid #00ff00", paddingBottom: '20px'}}/>


      <div style={{
        // width: '80%', 
        // margin: 'auto',
        // textAlign: 'center',
        paddingLeft: "20px",
        // alignItems: 'center',
      }}>

        {/* <SubTitle title="CONTACT ME"/> */}
        {/* <p><b>CONTACT ME: </b></p> */}
        <img src="https://i.ibb.co/ZKx8XZG/1663568817543.jpg" 

        
        // <img src="https://i.ibb.co/RQn7QQm/1610567748799.jpg" 
        style={{
          // borderRadius: "100px", 
          border: "2px solid #00ff00",
          margin: 'auto',
          width: '100px'
        }}/>
        
        <div>Joshua Davis</div>
        <div>~~~</div>

        <div>(404) 632 - 2124</div>
        <div>~~~</div>
        <div>josh.davis@berkeley.edu</div>
        <div>~~~</div>

        <div>Located in Atlanta, Georgia and Berkeley, California</div>
        <div>~~~</div>

        <a     
          style={{
            color: greenColor,
            padding: "0px",
            // textDecoration: 'none',
            // fontSize: '2.5vw',
            // width: '30vw',
            // textAlign: 'center',
            // margin: 'auto',
            // margin: '10px',
          }}
          href='https://www.linkedin.com/in/josh-davis-dev/' 
          target="_blank" 
          download>
          <b>Linkedin</b>
        </a>
        </div>
        <div style={{padding: '20px'}}/>

        <Lottie 
              options={shrugOptions}
              height={100}
              width={300}
              style= {{ 
                margin: 'auto', 
                pointerEvents: 'none',

              // scale: width > 500 ? 1 : 0.5

            }}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
              />
      </div>
    </body>
  );
}

export default Home;
