import './SpinDrawPage.css'
import React, {useState, useEffect} from 'react'
import Markdown from 'markdown-to-jsx';
import raw from './spinPolicy.txt';

export default function PrivacyPolicy() {

    const policy = `# Privacy Policy - SpinDraw 3D 
    This privacy policy states that “SpinDraw 3D” is a free mobile app created by Joshua Davis. 
    This is a drawing simulation app, where the user draws with spinning shapes.
    ​
    This page is used to inform visitors regarding my policies with 
    the collection, use, and disclosure of Personal Information if anyone decided to use my application.
    ​
    ## Information Collection and Use
    For a better experience, while using this Application, 
    I may require you to provide us with certain personally identifiable information. 
    The information that I request will be retained on your device and is not collected by me in any way.
    I do not collect or store any data.
    The Application was built using the Apple SpriteKit/SwiftUI Framework
    The Application uses the third party service AdMob that may collect information used to identify you.
    This Application does not collect location data information
    ​
    ## Security
    I value your trust in providing us your Personal Information, 
    thus we are striving to use commercially acceptable means of protecting it. 
    But remember that no method of transmission over the internet, 
    or method of electronic storage is 100% secure and reliable, 
    and I cannot guarantee its absolute security.
    ​
    ## Links to Other Sites
    This Application contains links to other sites. 
    If you click on a third-party link, you will be directed to that site. 
    Note that these external sites are not operated by me. 
    Therefore, I strongly advise you to review the Privacy Policy of these websites. 
    I have no control over and assume no responsibility for the content, privacy policies, 
    or practices of any third-party sites or services.
    ​
    ## Children’s Privacy
    These Services do not address anyone under the age of 13. 
    I do not knowingly collect personally identifiable information from children under 13. 
    If you are a parent or guardian and you are aware that your child has provided us with personal information, 
    please contact me so that I will be able to do necessary actions.
    ​
    ## Changes
    I may update our Privacy Policy from time to time. 
    Thus, you are advised to review this page periodically for any changes. 
    I will notify you of any changes by posting the new Privacy Policy on this page. 
    These changes are effective immediately after they are posted on this page.
    ​
    ## Contact Me
    If you have any questions or suggestions regarding this privacy policy, 
    do not hesitate to contact me via email at chess.ofx@gmail.com.
    `
    
    // const readTextFile = (file) => {
    //     fetch(raw)
    //         .then(r => r.text())
    //         .then(text => {
    //             console.log('text decoded:', text);
    //         });
    // }
    // const text = () => {
    //     fetch(raw)
    //         .then(r => r.text())
    //         .then(t => {
    //             console.log('text decoded:', t);
    //             // return t
    //         });
    // }

    // USE USEEFFECT AND DO THIS ON LOAD
    // () -> {fetch(raw)
    //     .then(r => r.text())
    //     .then(text => {

    //         // console.log('text decoded:', text);
    //     })})()
    
    
    return (

        <body className='SpinDrawPage'>
            <Markdown style={{color: 'wheat', whiteSpace: "pre-wrap", textAlign: 'left', padding: '50px'}}>
                {policy}
            </Markdown>
        </body>
    )
}
