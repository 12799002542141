import './MusicPage.css'
import React from 'react'
// import testpic from "../assets/testpic.jpg"; // Tell webpack this JS file uses this image
import {useEffect, useRef} from 'react'

function MusicPage({ setSel }) {

    useEffect(() => {
        setSel(1)
    }, [])

    // const MOMEmbed = () => {
    //     return (
    //         <iframe width="100%" height="150" src="https://odesli.co/embed/?url=https%3A%2F%2Fsong.link%2Ftjf78ptfkm5vt&theme=dark" frameborder="0" allowfullscreen sandbox="allow-same-origin allow-scripts allow-presentation allow-popups allow-popups-to-escape-sandbox" allow="clipboard-read; clipboard-write"></iframe>
    //     )
    // }

    const BigEmbedURL = (src) => {
        return (
            <div style={{maxWidth:"50%", padding:"50px", margin:'auto'}}>
                <div style={{
                position: "relative", 
                paddingBottom: "calc(56.25% + 52px)", 
                height: 0}}>
                    <iframe style={{position: 'absolute', top: 0, left: 0}} width="100%" height="100%" src={src} frameborder="0" allowfullscreen sandbox="allow-same-origin allow-scripts allow-presentation allow-popups allow-popups-to-escape-sandbox" allow="clipboard-read; clipboard-write"></iframe>
                </div>
            </div>
        )
    }

    const ColorsEmbed = () => {
        return (
            BigEmbedURL("https://odesli.co/embed/?url=https%3A%2F%2Fsong.link%2F8z7p2prnjvw3d&theme=dark")
        )
    }
    const MOMEmbed = () => {
        return (
            BigEmbedURL("https://odesli.co/embed/?url=https%3A%2F%2Fsong.link%2Ftjf78ptfkm5vt&theme=dark")
        ) 
    }
    const FlyAwayEmbed = () => {
        return (
            BigEmbedURL("https://odesli.co/embed/?url=https%3A%2F%2Fsong.link%2Fnbdztqxv63j4f&theme=dark")
        ) 
    }

    const bgtxt = ((num) => {
        let title = ""
        for (var i = 0; i < num; i++) {
            title += "FUZZ THEORY * FUZZ THEORY "
        }
        return title
    })(500)

    const bioText = ""
    // `
    //     In the suburbs of Atlanta producer rapper singer songwriter Fuzz Theory went brr and made music. 
    //     ~ Fuzz Theory 2021
    // `

    const SocialButton = ({href, name}) => {
        return (
            <a style={{
                padding: '10px',
                color: 'red',
                backgroundColor: 'blue',
                // width: '20%',
                height: '20px',
                textAlign: 'center',
                margin: 'auto',
                textDecoration: 'none',
                fontWeight:'bolder',
                fontSize: '2vw'
                // borderRadius: '10px'
            }}
            target="_blank"

            href={href}>
                <span>{name}</span>
            </a>
        )
    }

    return (
        <div className='MusicPage'>

            {/* <p className='backgroundText'>{bgtxt}</p> */}

            <div>
                <div style={{fontSize: "10vw", fontStyle: 'italic', fontWeight: 'bold', padding: "50px"}}>FUZZ THEORY</div>
                <div style={{    
                    opacity: "50%",
                    padding: "50px",
                    paddingTop: 0
                }}>{bioText}</div>

            {/* <img src={"https://i.ibb.co/SmDvfsV/testpic.jpg"} style={{width: '100%', height: '50vw'}}/> */}
            
            </div>

            <div style={{
                alignContent: 'center',
                // justifyContent: 'flex-end',
                // float: 'left',
                // margin: 'auto',
                padding: '20px',
                // width: '100vw'

            }}>

                <SocialButton href='https://www.instagram.com/fuzz_theory/' name="Instagram"/>
                <SocialButton href='https://www.beatstars.com/fuzztheorybeats/feed' name="BeatStars"/>
                <SocialButton href='https://music.apple.com/hr/artist/fuzz-theory/1562044541' name="Apple Music"/>
                <SocialButton href='https://open.spotify.com/artist/7iW9IHUXkP5GYOIX9ZtPa4' name="Spotify"/>

            </div>

            <div style={{
                    display: 'block',
                    paddingLeft: '2vw',
                    // paddingBottom: 100,
                    color: 'red',
                    fontWeight:'bolder',
                    fontSize:'2.5vw'
                }}>
                    <div style={{textAlign: 'left'}}>
                    <div>MIXING TRACKS: 25$</div>
                    <div>ALBUM MIXING (up to 40 minutes): 200$</div>
                    <div>MASTERING TRACKS: 25$</div>
                    <div>ALBUM MIXING (up to 40 minutes): 200$</div>
                    <div>(EMAIL INQUERY)</div>
                    </div>
            </div>

            <div>
                <div style={{fontSize: "5vw", textAlign: 'center' ,fontStyle: 'italic', fontWeight: 'bold', padding: "50px"}}>BEATS</div>
                
                <div style={{
                    display: 'block',
                    textAlign: 'center',
                    color: 'red',
                    fontWeight:'bolder',
                    fontSize:'3vw'
                }}>

{/* <div>{`
                        MIXING TRACKS: 25$
                        ALBUM MIXING (up to 40 minutes): 200$
                        MASTERING TRACKS: 25$
                        ALBUM MIXING (up to 40 minutes): 200$
                        (EMAIL INQUERY)
                    `}</div> */}

                <div style={{
                    paddingTop: '20px',
                }}>
                    <iframe src="https://player.beatstars.com/?storeId=123250" style={{display: "block", margin: "auto", position: "relative", width:"70vw", height:"70vw", style:"max-width:1024px;"}} />
                </div>
        
                </div>
            </div>

            
            <div>
            <div style={{fontSize: "5vw", 
                        textAlign: 'center',
                        fontStyle: 'italic', 
                        fontWeight: 'bold', 
                        padding: "50px"}}>NEW MUSIC</div>

                {/* <div className="subtitle">Colors</div> */}
                {ColorsEmbed()}            
                {/* <div className="subtitle">FLY AWAY</div> */}
                {FlyAwayEmbed()}
                
                {/* <div className="subtitle">MIND OF MINE</div> */}
                {MOMEmbed()}

            </div>



            <div style={{textAlign:"center", fontWeight: 'bold', fontSize:'2vw', padding: "20px"}}>
                <span>CONTACT ME AT </span>
                <a href='mailto:asquidsnightmare@gmail.com' style={{
                    color: "red"
                }}>ASQUIDSNIGHTMARE@GMAIL.COM</a>
                <span> FOR INQUERIES</span>

            </div>


        </div>
        
        )
}

export default MusicPage;