import './Footer.css'
import { FaLinkedinIn, FaFacebookF } from 'react-icons/fa'
import { AiFillInstagram, AiOutlineTwitter, AiFillGithub } from 'react-icons/ai'
import { RiYoutubeFill } from 'react-icons/ri'
import * as Colors from '../components/Colors'
import { borderRadius } from 'polished'


function Footer() {
  const LogoLink = ({link, color, component}) => {
  
  return (
    <a href={link} className='linkSocial' target="_blank" style={{
        // backgroundColor: color,
        backgroundColor: '#00ff00',

        // width: '7vw'
      }}>
      {/* <div className='socialImg'> */}
        {component}
      {/* </div> */}
    </a>
  )
  }
  return (
        <footer style={{
          // backgroundColor: Colors.cardBackground
          // backgroundColor: 'white'
              borderTop: '2px solid #00ff00',
              borderRadius: '0px'

          }}>
          <div className='socialBox'>
            <LogoLink link='https://www.linkedin.com/in/josh-davis-dev/' component={<FaLinkedinIn className='img'/>} color='#0077B5'/>
            <LogoLink link='https://github.com/JoshSweaterGuy' component={<AiFillGithub className='img'/>} color='black'/>
            <LogoLink link='https://www.instagram.com/joshua.alan.davis/' component={<AiFillInstagram className='img'/>}  color='#DD2A7B'/>
            <LogoLink link='https://www.facebook.com/profile.php?id=100014382936774' component={<FaFacebookF className='img'/>}  color='#355797'/>
            <LogoLink link='https://www.youtube.com/channel/UCMVOhlrCJq0GefeJ4K2_Nsg/featured' component={<RiYoutubeFill className='img'/>} color='#eb3518'/>
            {/* <LogoLink link='https://twitter.com/ChessOfx' component={<AiOutlineTwitter className='img'/>}  color='#08A0E9'/> */}

          </div>

        </footer>
  );
}

export default Footer;


