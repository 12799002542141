
// MARK - GENERAL

export const rootLine = "JPT ~ >"
export const commands = ['cat', 'ls', 'help', 'about', 'clear']
export const version = '1.0'


// MARK - ART
export const logoart = `
()=()   ()-()   ()=()   ()-()
('Y')   (':')   (^;^)   ('&')
q . p   d . b   C . C   c . c
()_()   ()_()   ()_()   ()=()
`

export const aboutart = `            @ @ @    
       []___          
      /    /\\____   
(~)  /_/\\_//____/\\ 
 |   | || |||__|||   `.split('\n')


export const initialLines = logoart.split('\n').concat([      
    "Welcome to the Josh Portfolio Terminal(JPT).",  
    `Version ${version}`,
].concat(
[
    "Input help for a list of commands",
    ".", ".", ".", ".", ".", ".", ".", ".",
    ".", ".", ".", ".", ".", ".", ".", "."
]))

// MARK - COURSE INFO

export const courses = {
'CS61A': `
Structure and Interpretation of Computer Programs
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Introduction to recursion and functional programming
* Built a scheme interpreter in python
`.split("\n"),

'CS61B': `
Data Structures
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Implemented Hash-Tables, Trees, Deques
* Pathfinding Algorithms, Sorting, Asymptotics

Projects:
* BYOW: Build Your Own World
    - 2D maze walking game camera following player
    - Built a randomly generated seeded world generator
    - Gradient based lighting following player

* Gitlet (Git implementation in Java)
    - Add and Commit changes to commit tree
    - Checkout and brach past commits
    - Push/Pull to remote repo      
`.split("\n"),

'CS61C': `
Great Ideas in Computer Architecture
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Learned C and RISC-V assembly Languages
* Classified handwritten digits in RISC-V
* Used Logism to create and design RISC-V CPU
`.split("\n"),

'CS70': `
Descrete Math and Probability Theory
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Modular arithmatic, Hypercubes, Graphs, Induction
* RSA encryption, polynomials, and error correcting codes
* Infinite sets, Counting, Probability
`.split("\n"),

'EECS16A': `
Designing Information Devices and Systems I
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Linear algebra: NULL spaces, Eigen Values
* Nodal Analysis, KCL and KVL, and RC circuits
* Fitting data with Least Squares and cross correlation 
* Made a resistive and capacitive touch screen
`.split("\n"),

'EECS16B': `
Designing Information Devices and Systems II
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Analog low pass, high pass, band pass filters
* RLC circuits and Fourier Transform
* Signal processing and classification with SVD and PCA

Project:
* SIXT33N: Robot car activated with voice commands
	- Created a robot car with microcontroller and motors
	- Used open control feedback to make the car drive straight
	- Moved car with voice commands fitted through SVD and PCA
`.split("\n"),

'MUSIC158A': `
Sound and Music Computing with CNMAT Technologies
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Used Max/MSP programming environment to create soundscapes
* Created a DAW-like work environment and created a hip-hop beat
`.split("\n"),

'MUSIC158B': `
Situated Instrument Design for Musical Expression
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Used Max/MSP to create spatial soundscapes
* Explored physical experiences of music and sound

Project:
* Hacked Otomatone: A musical instrument
	- added output jack
    - replaced passive sound innards with arduino MKR-Zero
    - used arduino to play samples and simulated other sounds

`.split("\n"),

'CS170': `
Efficient Algorithms and Intractable Problems
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Dynamic Programming, Linear Programming, and Divide and Conquer Algorithms
* NP-Complete Problems and runtime anylsis with Recurrence Relations
`.split("\n"),

'CS188': `
Introduction to Artificial Intelligence
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Simulated Annealing, Genetic Algorithms, Hill-Climbing, and Path-Finding
* Created a AI program to play Pacman
* Probability of Bayes Nets, MEU, and Neural Networks
* Built a clissifier to classify handwritten digits
`.split("\n"),
'CS162': `
Operating Systems and Systems Programming
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Description Coming soon
`.split("\n"),
'DATA100': `
Principles and Techniques of Data Science
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Description Coming soon
`.split("\n"),
'DATA8': `
The Foundations of Data Science
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Description Coming soon
`.split("\n"),

'EECS151': `
Introduction to Digital Design and Integrated Circuits
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Description Coming soon
`.split("\n"),

'CS152': `
Introduction to Computer Architecture
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Description Coming soon
`.split("\n"),
'CS194': `
Special Topics in Decentralized Finance
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* Description Coming soon
`.split("\n"),
}


