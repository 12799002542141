import React, { useEffect, useRef, useState } from 'react'
import './TerminalAboutMe.css'
import * as TS from './TerminalStrings.js'

function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(value => value + 1);
}
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
 function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

export default function TerminalAboutMe() {
    const forceUpdate = useForceUpdate();
    const { height, width } = useWindowDimensions();


    const inputref = useRef(null);
    const [searchInput, setSearchInput] = useState("");
    const [lines, setlines] = useState(TS.initialLines);

    function fontSize() {
        // const { height, width } = getWindowDimensions()
    
        if (width < 800) {
            if(width < 500) {
                return "10px"
            }
            return "12px"
        }
        return "16px"
      }

    useEffect(()=>{
       // current property is refered to input element
       inputref.current.focus({
        preventScroll: true
      });
    },[])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            bufferInput(searchInput.trim())
            setSearchInput("") 

        }
      }
    
    const bufferInput = (line) => {
        console.log(line)
        let newLines = lines
        
        for(let i=1; i < lines.length; i++) {
            newLines[i - 1] = lines[i]
        }

        if (line !== "") {
            let lineSplit = line.split(' ')
            let result = ""
            let command = lineSplit.shift().trim().toLowerCase()
            console.log(command)

            result = interpret(command, lineSplit)

            if (TS.commands.includes(command)) {
                newLines[lines.length - 1] = TS.rootLine + " " + line
                
                for (let r of result) {
                    newLines.shift()
                    newLines.push(r)

                }
            
            } else {
                newLines[lines.length - 1] = "JPT: command not found: " + command + " >> type help to learn more"

            }

        } else {
            newLines[lines.length - 1] = TS.rootLine
        }

        setlines(newLines)
        forceUpdate()

    }

    const interpret = (command, args) => {

        if (command === 'ls') {
            let courseStrs = []
            let amtPerLine = 8
            let amt = 0
            let line = ""
            for (let course in TS.courses) {
                if (amt === amtPerLine) {
                    courseStrs.push(line)
                    amt = 0
                    line = ""
                }
                line += course + " "
                amt += 1

            }
            courseStrs.push(line)
            return courseStrs
        }

        if (command === 'help') {
            return ["Looks like you are new around here...",
                    " ",
                    "\tclear     \tClears the screen",
                    "\tabout     \tLearn more about JPT and This website",
                    "\tls        \tLists my technical courses I have taken",
                    "\tcat [name]\tLearn more about a course I have taken",
                    // "\tdev-log   \tA dev-log of updates to JPT",
                    ""
                ]

        }

        if (command === 'clear') {
            return lines.map(l => {return '.'})
        }

        if (command === 'about') {
            console.log(TS.aboutart)
            return TS.aboutart.concat(
                [`VERSION: ${TS.version}`,
                '~~~~~~~~~~~~~~~~~~~~~~~~~',
                "",
                "The Josh Porfolio Terminal(JPT) is a prototype portfolio of the future.",
                 "The JPT written with React web framework in Javascript by Joshua Davis in summer 2022.",
                 "This interactive portfolio draws insperation from a BASH terminal.",            
                 " "
                ])
        }

        if (command === 'cat') {
            if (args.length === 0) {
                return ["cat: : No such file or directory"]
            }
            const arg = args[0].trim().toUpperCase()

            for (let course in TS.courses) {
                console.log(course)
                if (course === arg) {
                    return TS.courses[course]
                }
            }

            return [`cat: ${arg}: No such file or directory`]

        }


        return [""]
    }

    const backgroundColorBlack = '#050505'

  return (
    
    <div style={{color: '#00ff00', 
                 backgroundColor: backgroundColorBlack,
                 margin: 'auto',
                 position: 'relative',
                 justifyContent: 'left',
                 alignContent: 'flex-start',
                 textAlign: 'left',
                 padding: '20px',
                 fontFamily: 'Menlo',
                 borderRadius: '10px',
                 width: width < 1100 ? `${width - 100}px` : '1000px',
                //  height: '500px',
                 letterSpacing: '1px',
                 border: '1px solid #00ff00',
                 fontSize: fontSize()
                //  fontSize: '2vw'
                //  paddingRight: '50%'
                 }}
            onClick={() => {
                inputref.current.focus()
            }}
            >
    
        
        {lines.map(l => {
            return <div
            style={{
                whiteSpace: 'pre-wrap',
                tabSize: 4,
                
            }}
            
            >{l} </div>
        })}

        <div style={{ 
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
            

        }}>
        <div>{TS.rootLine}</div>
        <div style= {{
            paddingLeft: '5px',
            width: width > 1000 ? '900px' : `${width - 200}px`,

        }}>
        
        <input 
            style={{backgroundColor: backgroundColorBlack, 
                    textDecoration: backgroundColorBlack,
                    letterSpacing: '1px',
                    textShadow: '3.443339761481782px 0 1px rgba(0,30,255,0.5), -3.443339761481782px 0 1px rgba(255,0,80,0.3), 0 0 3px',
                    fontSize: fontSize()
                }}
            className='inputbox'
            ref={inputref}
            onChange={e => setSearchInput(e.target.value)}
    
            value={searchInput}
            // style={{
            //     backgroundColor: 'black',
            //     color: '#00ff00',
            //     fontFamily: 'Menlo',
            //     width: '100%',
            //     fontSize: '16px',
            //     border: '0px',
            //     textDecorationColor: 'black',
            //     focus: {
            //         border: '0px',
            //     }
            //     // paddingLeft: '10px'

            // }}
            contenteditable="true"
            onKeyDown={handleKeyDown}
            />
        </div>

        </div>

        {/* <input>enter</input> */}
    </div>
  )
}
