import "./Home.css";
// import {useEffect, useRef, useState} from 'react'
// import {Link} from "react-router-dom";
// import { Wave } from "react-animated-text";
// import Image from "react-bootstrap/Image";
// import ReactMarkdown from "react-markdown";
// import styled from "styled-components";

const Project = ({title, year, description, jsxData, onTapGesture}) => {

    return (
      <div style={{paddingBottom: '30px'}}>
      <div className="project" onClick={onTapGesture}>
        <div style={{fontSize:'30px'}}><b>{title}</b></div>
        <div style={{paddingBottom:'20px'}}><b>{year}</b></div>

        {(() => {
          if (jsxData != null) {
            console.log(title)
            return(
            <div>
              {jsxData()}
            </div>
            )
          }
          return(<div/>)
        })()
        }

        <p style={{textAlign: 'left'}}>{description}</p>

      </div>
      </div>
    )
}

const SpinLine = () => {
    let spinners = []
    let startpx = 1
    let amt = 5
    let delays = 10
    for (let i=0; i < amt; i++) {
        //"${startpx + (i*20)}px"
        spinners.push(<SpinBox left= {startpx + i*20 + "px"} delay={i * delays + 'ms'}/>)
    }

    return(
        <>
        {spinners}

        </>
    )
}

const SpinBox = ({left, delay}) => {
    return (
        <div className="spinBx" style={{left: left, animationDelay: delay}}/>

    )
}

const WebsiteProject = () => { 
    return <Project 
        title="josh-davis.dev" 
        year="SPRING 2021-PRESENT" 
        description={`
        An ongoing personal portfolio website. 
        Links to my projects, music, and a contact information. 
        Built with React web framework, hosted on Firebase hosting.
        `} 
        />}

const TTTProject = () => {
    return <a href="https://apps.apple.com/tr/app/tic-tac-toe-forever/id1605896081" 
    target="_blank"
    style={{ textDecoration: "none" }}
    ><Project 
    title="Tic Tac Toe: Forever" 
    year="2022-PRESENT" 
    description={`
      IOS mobile Tic Tac Toe game with custom maps.
      created with SwiftUI.
    `} 
    />
    </a>
}

const RiscVCPUProject = () => {
  return<Project 
  title="RISC-V Datapath with Logisim" 
  year="Fall 2021" 
  description={`
    In this project in CS61C, we built a RISC-V CPU using logic gates with Logisim. 
    This inludes Control Logic, ALU, Register File, Branch Comparator, and Immidiate Generator.
  `} 
  />
}

const CS151Project = () => {
  return<Project 
  title="3 stage pipelined CPU built in Verilog for FPGA" 
  year="Fall 2022" 
  description={`
    In this project in EECS151, I built a 3 stage pipelined RISC-V CPU in Verilog. I included a branch predictor and cache. 
    The datapath included a bios and could run programs uploaded from a PC via a UART and ran on a FPGA.
  `} 
  />
}

const PintosCS162 = () => {
  return<Project 
  title="Implemented various Operating System functions in PintOS" 
  year="Fall 2022" 
  description={`
    In this project in CS162, with a group built concurrency&scheduling, virual memory, and a dynamic filesystem into PintOS (an educational open source OS).
  `} 
  />
}

const BranchPredictorCS152 = () => {
  return<Project 
  title="Implemented & Optimized Branch Prediction Algorithm" 
  year="Spring 2023" 
  description={`
    In this project in CS152, I built a branch prediction algorithm that had to perform well on a variety of unique programs. 
    I implemented a tournament style branch predictor with a global history saturing counter and a hybrid/local history counter.
    As well I exploreing nexting tourament style predictors for more fine grain adjustments on misses. As well explored creating a TAGE branch predictor.
  `} 
  />
}
const CacheReplacementPolicies = () => {
  return<Project 
  title="implemented various Operating System functions in PintOS" 
  year="Fall 2022" 
  description={`
    In this project in EECS151, I built a 3 stage pipelined RISC-V CPU in Verilog. I included a branch predictor and cache. 
    The datapath included a bios and could run programs uploaded from a PC via a UART and ran on a FPGA.
  `} 
  />
}

// const MatmulPolicies = () => {
//   return<Project 
//   title="implemented various Operating System functions in PintOS" 
//   year="Fall 2022" 
//   description={`
//     In this project in EECS151, I built a 3 stage pipelined RISC-V CPU in Verilog. I included a branch predictor and cache. 
//     The datapath included a bios and could run programs uploaded from a PC via a UART and ran on a FPGA.
//   `} 
//   />
// }

const CounterstrikeMarketProject = () => {
  return <Project 
  title="CS-SCHEME"
  year="Spring 2023-PRESENT" 
  description={`
  "Counterstrike 2" market tool to buy and sell in-game skins from a variety of markets based on a variety of metrics.
  Currently used to buy undervalued skins based on expected value of in-game trade-up contracts. 
  The project contains 4 Docker containers Market-Data Collection, Data-Interpretation & Possible Trades Generation, Backend Server, and a Frontend App.
  Python is used to collect information with multiple threads, generate trades, and as a backend with FASTAPI.
  The frontend app was built using svelte and typescript.
  `} 
  />

}

const SortaBallProject = () => {
    return <a href="https://apps.apple.com/tr/app/sorta-ball/id1505220306" 
    target="_blank"
    style={{ textDecoration: "none" }}
    >
    <Project 
    title="SORTA Ball"
    year="2019-PRESENT" 
    description={`
    Mobile drawing application available on the app store.
    This app is an arcade style game where the user has to sort balls in high precision frantic fasion.
    As you increase your high-score you unlock new ball skins to sort! 
    Built using Swift, UIKit, SwiftUI, and SpriteKit. 
    `} 
    />
  </a>

}

const SpinDrawProject = () => {
    return <a href="https://apps.apple.com/tr/app/spindraw-3d/id1326707226" 
    target="_blank"
    style={{ textDecoration: "none" }}
    ><Project 
    title="SpinDraw 3D"
    year="2018-PRESENT" 
    jsxData={() => {
      return <div style={{display: 'flex', flexDirection: 'row'}}>
        <SpinLine/>
      </div>

    }}
    description={`
    Mobile drawing application available on the app store.
    App lets users make crazy drawings with spinning shapes.
    Created to help kids with disgraphia learn their letters
    by being able to trace them with fun shape brushes.
    This app has since evolved into a full featured drawing app.
    More information on the app can be found on the app store page and the apps tab of this website.
    Built using Swift, UIKit, SwiftUI, and SpriteKit. 

    `} 
/>
</a>
}

const BYOWProject = () => {
    return <Project 
        title='BYOW: Build Your Own World'
        year='SPRING 2021'
        description={`
        In this project in CS61B, we had the freedom to create a dungeon crawler game with many different unique features.
        In our game the goal was to visit objectives while fight enemies. One interesting and difficult feature to add was a sidescrolling mechanic that allowed the game to play more like a dungeon crawler.
        Also, another mechanic I added was realistic lighting that allowed the player to not know the whole map to find the objectives.
        All these feature are showed off in the above youtube video.
        `}
        jsxData={() => {
            return (
            <iframe 
                style={{
                width:"50vw" ,
                height:"30vw",
                border: '2px solid #00ff00',
                }}
                src="https://www.youtube.com/embed/tGX1CwPM9eA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" >
            </iframe>
            )

        }}
    />
}
    
const GitletProject = () => <Project 
    title="Gitlet"
    year="SPRING 2021" 
    description={`
    In this project for CS61B, I created a git version control system in Java.
    This project was given with minimal skeleton code to really allow me to design 
    my own implementations from the ground up. In my implementation, I used hashing to 
    save modified files in a directory and cross-refrence changes to the previous commit.
    Gitlet allowed users to commit, checkout, check status, branch, merge, push and pull to another directory, and more.
    `} 
/>

const CS170Project = () => <Project 
    title="CS170 Optimization Project"
    year="SPRING 2022" 
    description={`
    In this group project for CS170, my group created a modified simulated annealing algorithm to optimize the optimal placement of cell towers to minimize energy.
    We rebuilt the starter code from the ground up and created a visualization tool to help us debug and understand our outputs.
    As a group we had to run our algorithm on over 500 inputs ranging from small to large scale. Overall our group ranked 6th place out of over 100 groups.
    `} 
  />

const NumCProject = () => <Project 
    title="NumC"
    year="FALL 2021" 
    description={`
    In this project for CS61C, I created a version of NumPy written in C. As well I made many optimizations using SIMD with intel intrisics library and multithreading with openMP.
    As well I made optimizations based on cache locality and memory layout.
    `} 
  />

const HackedOtomatoneProject = () => {
    return <Project 
    title={"\"Hacked\" Otomatone"}
    year="SPRING 2022" 
    description={`
    In this project for MUSIC 158B, I tore apart a music toy called an otomatone and replaced it's innards with a Arduino MKR Zero.
    This allowed me to play and modify unique sounds on the toy. As well, in the circuit I added an output jack to allow me to record the toy directly from my computer.
    `} 
  />
}

const LavaMarkupGenerationProject = () => {
    return <a href="https://github.com/JoshSweaterGuy/lava" 
    target="_blank"
    style={{ textDecoration: "none" }}
    >
      <Project 
    title="LAVA Markup Generation"
    year="SPRING 2022-PRESENT" 
    description={`
    I am currently creating an open source inline markup generation and templating language built for Obsidian Note with node.js. 
    This includes a command line tool to specify note and template directories, parsing templates (.lava files), and efficiently parsing and injecting mock ups and data into notes.
     `}
  />
  </a>

}

const MinecraftAWSServerProject = () => {
    return <Project 
      title="Minecraft AWS Server & Discord Bot"
      year="SPRING 2022" 
      description={`
      I created a Mincraft server in an AWS EC2 instance. For the functionality I needed, I made AWS Lambda functions that when called turned on and off the server.
      I then built and deployed to another AWS Server a Discord bot with commands that call the AWS Lambda functions so I can easily boot up the server and check how many people are on through discord.
      `} 

      />
}

const GaslightProject = () => {
    return <a href="https://apps.apple.com/tr/app/gaslight-me-magic-8-ball/id1625544723" 
    target="_blank"
    style={{ textDecoration: "none" }}
    >
    <Project 
    title="Gastlight Me! Magic 8 Ball"
    year="SPRING 2022" 
    description={`
    In this mobile app, the user can customize their own magic 8 ball. This app was created with SwiftUI.
    `} 
  />
  </a>
}

export { WebsiteProject, TTTProject, SortaBallProject, SpinDrawProject, BYOWProject, GitletProject, 
    NumCProject, HackedOtomatoneProject, LavaMarkupGenerationProject, MinecraftAWSServerProject, GaslightProject, CS170Project, RiscVCPUProject, CS151Project, CounterstrikeMarketProject, PintosCS162, BranchPredictorCS152 };
