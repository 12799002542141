import './AppsPage.css'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Colors from '../components/Colors'
import { motion } from 'framer-motion'
import {useEffect, useRef} from 'react'

function AppsPage({ setSel }) {
    useEffect(() => {
        setSel(2)
    }, [])

    // const SpinBox = ({color, left, delay}) => {
    //     return (
    //         <div className="spinBox" style={{left: left, borderColor: color, animationDelay: delay}}/>

    //     )
    // }

    // const SpinBox2 = ({color, left, delay}) => {
    //     return (
    //         <div className="spinBox2" style={{left: left, borderColor: color, animationDelay: delay}}/>

    //     )
    // }


    // const SpinLine = () => {
    //     let spinners = []
    //     let startpx = 20
    //     let amt = 20
    //     for (let i=0; i < amt; i++) {
    //         //"${startpx + (i*20)}px"
    //         spinners.push(<SpinBox left= {startpx + i*20 + "px"} color='blue' delay={i * 50 + 'ms'}/>)
    //     }

    //     return(
    //         <>
    //         {spinners}

    //         </>
    //     )
    // }

    // const SpinShort = () => {
    //     let spinners = []
    //     let step = 20
    //     let delay = 50
    //     let amt = 10
    //     let amtboxpx = 400
    //     let ypos = "50px"


    //     for (let i=0; i < amt; i++) {
    //         spinners.push(<SpinBox2 left= {amtboxpx/2 - (amt/2 + 2) * step + i*step + "px"} bottom= {ypos} color='blue' delay={i * 50 + 'ms'}/>)
    //     }

    //     return(
    //         <div className="spinList2" style={{width: amtboxpx + 'px'}}>
    //         {spinners}

    //         </div>
    //     )
    // }
    const [hoverState, setHoverState] = useState(0)

    // const SpinBox3 = ({color, left, delay}) => {
    //     return (
    //         <div className="spinBox3" style={{left: left, borderColor: color, animationDelay: delay}}/>

    //     )
    // }

    const SpinBox4 = () => {
        return (
            <div className="spinBox3" style={{borderColor: 'blue'}}/>

        )
    }

    // const SpinShort2 = () => {
    //     let spinners = []
    //     let step = 20
    //     let delay = 50
    //     let amt = 1
    //     let amtboxpx = 400
    //     let ypos = "50px"


    //     for (let i=0; i < amt; i++) {
    //         spinners.push(<SpinBox3 left= {amtboxpx/2 - (amt/2 + 2) * step + i*step + "px"} bottom= {ypos} color='blue' delay={i * 50 + 'ms'}/>)
    //     }

    //     return(
    //         <div className="spinList2" style={{width: amtboxpx + 'px'}}>
    //         {spinners}

    //         </div>
    //     )
    // }

    const BallSpin = () => {
        const Ball = (color) => {
            return(
                <div className="ball" style={{backgroundColor: color}}/>
            )
        }
        return(
            <div className="ballBox">
                <div className="ball"/>
                <div className="ball2"/>

            </div>

        )
    }

    const XO = () => {
        const Ball = (color) => {
            return(
                <div className="ball" style={{backgroundColor: color}}/>
            )
        }
        return(
            <div className="ballBox">
                <div className="ticX">
                    <div className="ticLX"/>
                    <div className="ticRX"/>
                </div>

                <div className="ticO"/>

            </div>

        )
    }
    
    const EightBall = () => {
        return(
            <div className="ballBox">
                <div className="eightBall"><b>8</b></div>
                {/* <div className="ball2"/> */}

            </div>

        )
    }
    const Spacer = () => {
        return <div style={{padding: "50px"}}/>
    }

    const SpinDrawButton = ({backgroundColor, textColor, onMouseOver, onMouseOut}) => {
        return (
            // <a href='https://apps.apple.com/us/app/spindraw-3d/id1326707226#?platform=iphone'
            // style={{textDecoration: 'none'}}
            // target="_blank"
            // onMouseOver={onMouseOver}
            // // onMouseUpCapture={onMouseOut}

            // >
            <Link to='/Spindraw3D' style={{textDecoration: 'none'}}>

                <div 
                // onMouseOver={() => setHoverState(1)}
                // onMouseOut={() => setHoverState(0)} 
                className='card' style={{backgroundColor: backgroundColor}}>
                    <div className="cardTitle" style={{color: textColor, fontSize: "6vw"}}>SpinDraw 3D</div>
                    {/* <SpinShort/> */}
                    {/* <SpinShort2/> */}
                    <SpinBox4/>

                </div>

                </Link>

            // </a>


        )
    }

    const SortaBallButton = ({backgroundColor, textColor, onMouseOver}) => {
        return (
        <motion.a href='https://apps.apple.com/us/app/sorta-ball/id1505220306'
        // whileHover={{ scale: 1.1, boxShadow: '20px 12px rgb(43, 43, 43)'}}
        // onMouseOver={onMouseOver}
        // onMouseLeave={() => setHoverState(0)} 
        style={{textDecoration: 'none'}}
        target="_blank"
        >
        {/* <Link to='/apps/sortaball' style={{textDecoration: 'none'}}> */}
            <div className='card' style={{backgroundColor: backgroundColor}}>
                <div className="cardTitle" style={{color: textColor, fontSize: "6vw"}}>SORTA Ball</div>
                <BallSpin/>
            </div>
        {/*  </Link> */}
        </motion.a>

        )
    }

    const TicTacToeButton = ({backgroundColor, textColor}) => {
        return (
        <motion.a href='https://apps.apple.com/us/app/tic-tac-toe-forever/id1605896081'
        // whileHover={{ scale: 1.1, boxShadow: '20px 12px rgb(43, 43, 43)'}}
        // onMouseOver={onMouseOver}
        // onMouseLeave={() => setHoverState(0)} 
        style={{textDecoration: 'none'}}
        target="_blank"
        >
        {/* <Link to='/apps/sortaball' style={{textDecoration: 'none'}}> */}
            <div className='card' style={{backgroundColor: backgroundColor}}>
                <div className="cardTitle" style={{color: textColor, fontSize: "6vw"}}>Tic Tac Toe: Forever</div>
                <XO/>
            </div>
        {/*  </Link> */}
        </motion.a>

        )
    }

    const EightBallButton = ({backgroundColor, textColor}) => {
        return (
        <motion.a href='https://apps.apple.com/us/app/gaslight-me-magic-8-ball/id1625544723'
        // whileHover={{ scale: 1.1, boxShadow: '20px 12px rgb(43, 43, 43)'}}
        // onMouseOver={onMouseOver}
        // onMouseLeave={() => setHoverState(0)} 
        style={{textDecoration: 'none'}}
        target="_blank"
        >
        {/* <Link to='/apps/sortaball' style={{textDecoration: 'none'}}> */}
            <div className='card' style={{backgroundColor: backgroundColor}}>
                <div className="cardTitle" style={{color: textColor, fontSize: "6vw"}}>Gaslight Me! Magic 8 Ball</div>
                <EightBall/>
            </div>
        {/*  </Link> */}
        </motion.a>

        )
    }

    let hs1 = 'Draw with spinning shapes and more in SpinDraw 3D'
    let hs2 = 'Use quick reflexes while sorting balls in SORTA Ball'

    return (
        <body className='AppsDiv'>

            <div className="borderDiv">
                <h1 className="subtitle">IOS APPS</h1>
                {/* <span>So I make apps and stuff I guess...</span> */}
                <Spacer/>

                {/* <span>d</span> */}
                <div className='content'>
                    <Spacer/>
                    
                    <SpinDrawButton 
                    onMouseOver={()=>{
                        setHoverState(1)
                    }} 
                    onMouseOut={()=>{
                        setHoverState(0)
                    }} 
                    backgroundColor={Colors.darkerGray} textColor='white'/>

                    <Spacer/>

                    <SortaBallButton 
                    onMouseOver={()=>{
                        setHoverState(2)
                    }} 
                    backgroundColor={Colors.darkerGray} textColor='white'/>

                    <Spacer/>

                    <TicTacToeButton
                    backgroundColor={Colors.darkerGray} textColor='white'/>
                    <Spacer/>

                    <EightBallButton
                    backgroundColor={Colors.darkerGray} textColor='white'/>

                </div>
                <Spacer/>

                {/* <span className='words'>{(() => {
                switch(hoverState) {
                    case 0: return '';
                    case 1: return hs1;
                    case 2: return hs2;
                }
                })()}</span> */}

            </div>

        </body>
    )
}

export default AppsPage
