import '../navTools/TopNav.css'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
function NavItem(props) {

  return (
    // <div className='NavBtn' onClick={props.onClick}>
      // {/* onClick={props.onClick} */}
    <Link className= {props.isBig ? 'bigSelNavItem': 'NavItem'} to= {props.link} onClick={props.onClick}>      
      {
        (() => { 
          if(props.component == undefined) {
            return <div>{props.name}</div>

          } else {
            return props.component

          }
        })()
      }
    </Link>
    // {/* <div className='FillBar'/> */}
    // </div>
  )
}
export default NavItem;
