import './TopNav.css';
import NavItem from '../components/NavItem';
import styled from 'styled-components';
import { useState } from 'react'
import { GiSpikyExplosion } from 'react-icons/gi'
import * as Colors from '../components/Colors'

const TopLogo = styled.div`
  /* margin: auto; */
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;

  margin-right: 2%;
  color: black;
  font-weight: bold;
`

function TopNav({ sel, setSel }) {
  // const [sel, setSel] = useState(0)

  return (
        <header className="TopNav" style={{backgroundColor: Colors.background}}>
          {/* <TopLogo>E.F.</TopLogo> */}
          <div className="Tabs">
            {/* <TopLogo>E.F.</TopLogo> */}
            <NavItem isBig = {sel === 0} name="About." link='/' onClick={() => setSel(0)} 
            // component={<GiSpikyExplosion />}
            />
            {/* <NavItem isBig = {sel == 0} name="Home." link='/' onClick={() => setSel(0)}/> */}
            {/* <div onClick={() => setSel(0)} >About.</div>
            <div onClick={() => setSel(1)} >Music.</div>
            <div onClick={() => setSel(2)}>Apps.</div> */}

            <NavItem isBig = {sel === 1} name="Music." link='/music' onClick={() => setSel(1)}/>
            <NavItem isBig = {sel === 2} name="Apps." link='/apps' onClick={() => setSel(2)}/>
          </div>
        </header>
  );
}

export default TopNav;
