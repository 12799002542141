// import '../../AppsPage.css'
import './SpinDrawPage.css'
import React, {useState, useEffect} from 'react'
import useMousePosition from '../../../hooks/useMousePosition'
import { Link } from 'react-router-dom';

export default function SpinDrawPage() {
    const {x, y} = useMousePosition();
    const hasMovedCursor = typeof x === "number" && typeof y === "number";
    const mouseA = []

    const spawnSpinner = () => {

    }
    
    const SpinBox2 = ({color, left, top, delay, len}) => {
      return (
          <div className="spinBox2" style={{left: `calc(${left}px - ${len/2}px)`, top: `calc(${top}px - ${len/2}px)`, borderColor: color, animationDelay: delay, width: len, height: len}}/>

      )
  }

  // useEffect(() => {
  //   mouseA.push({x, y})
  // })

    return (
      <body className='SpinDrawPage'>
        <Link to='/Spindraw3D/privacy'>

          <div>
            Privacy Policy
          </div>
        </Link>

        <a href='https://apps.apple.com/us/app/spindraw-3d/id1326707226'>

        <div>
          APP STORE PAGE
        </div>
        </a>
        {/* {hasMovedCursor
          ? `Your cursor is at ${x}, ${y}.`
          : "Move your mouse around."}
        {
          "len " + mouseA.length
        }
          {
            mouseA.map(({xm, ym}) => {
            
              <SpinBox2 left={xm} top={ym} len={20}/>

            })

          } */}

        <SpinBox2 left={x} top={y} len={20}/>

      </body>
    )
}
